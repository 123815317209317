import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import Header from "../components/Header";

const DevNotesPage = ({ location }) => {
  const { devNotesPosts } = useStaticQuery(
    graphql`
      {
        devNotesPosts: allMarkdownRemark(
          filter: { fields: { folder: { eq: "dev-notes" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "YYYY MMMM DD")
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout preTitle="Dev notes" pathname={location.pathname}>
      <Header />
      <div
        css={css`
          max-width: 90rem;
          margin: auto;
          padding: 3rem 1rem 0 1rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
      >
        <h2>Dev notes</h2>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            max-width: 42.5rem;
            margin: auto;
            flex-grow: 1;
          `}
        >
          {devNotesPosts.edges.map(({ node }) => (
            <div key={node.id}>
              <Link
                to={node.fields.slug}
                css={css`
                  color: unset;
                `}
              >
                <h3
                  css={css`
                    margin-bottom: 0.4rem;
                  `}
                >
                  {node.frontmatter.title}
                </h3>
              </Link>
              <span
                css={css`
                  font-size: 0.9rem;
                `}
              >
                {node.frontmatter.date}
              </span>
            </div>
          ))}
        </div>
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <StaticImage
            alt="Illustration"
            src="../assets/illustrations/Rocket_Pen.png"
            width={540}
            formats={["auto", "webp", "avif"]}
            placeholder={"tracedSVG"}
          />
        </div>
      </div>
    </Layout>
  );
};

export default DevNotesPage;
